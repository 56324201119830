<template>
  <!-- 宠物类别修改页面 -->
  <moe-page title="宠物类别修改">
    <moe-form ref="categoryForm" :model="category" :rules="categoryRules">
      <el-form-item label="宠物类别" prop="varieties">
        <el-input
          v-model.trim="category.varieties"
          placeholder="请输入宠物类别"
          maxlength="50"
          clearable
        />
      </el-form-item>
      <el-form-item label="宠物类型" prop="pesType">
        <moe-select
          v-model="category.pesType"
          type="petType"
          placeholder="选择宠物类型"
        />
      </el-form-item>
      <el-form-item label="宠物体型" prop="shapes">
        <moe-select
          :isMultiple="true"
          v-model="category.shapes"
          type="petContour"
          placeholder="选择宠物体型"
        />
      </el-form-item>
      <el-form-item label="宠物体重范围 / kg" required>
        <div class="l-number-range">
          <el-form-item label="" prop="weightMin">
            <el-input-number
              v-model="category.weightMin"
              :min="0"
              :max="100"
              :controls="false"
              placeholder="体重范围 - 小"
              @blur="petWeightChange"
            ></el-input-number>
          </el-form-item>
          <span class="l-number-range__symbol"></span>
          <el-form-item label="" prop="weightMax">
            <el-input-number
              v-model="category.weightMax"
              :min="0"
              :max="100"
              :controls="false"
              placeholder="体重范围 - 大"
              @blur="petWeightChange"
            ></el-input-number>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="宠物主图" prop="img">
        <moe-upload-file
          v-model="category.img"
          ossKey="PET_TYPE"
          upload-text="上传主图"
          :default-file-list="imageList"
          @change="$refs.categoryForm.validateField('img')"
        />
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="categoryLoad"
          @click="categoryInfoSubmit"
        >
          {{ categoryLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
/* 上传文件组件 */
export default {
  name: 'categoryAdd',
  data() {
    //宠物类别信息
    const category = {
      varieties: '', //名称
      pesType: '', //类型
      shape: '', //体型
      shapes:[], //体型
      weightMin: 0, //最小体重
      weightMax: 0, //最大体重
      img: '', //宠物主图
    };

    //宠物类别数据校验
    const verify = this.$moe_verify.verifyForm;
    const categoryRules = {
      varieties: verify.isExtent(
        ['请输入宠物类别', '长度不能超过 1 ~ 10'],
        '1~10'
      ),
      pesType: verify.isEmpty('请选择宠物类型'),
      shapes: verify.isEmpty('请选择宠物体型'),
      weightMin: verify.isEmpty('请输入宠物体重 - 最小值'),
      weightMax: verify.isEmpty('请输入宠物体重 - 最大值'),
      img: verify.isEmpty('请上传宠物主图'),
    };

    return {
      category, //宠物类别信息
      categoryRules, //宠物类别数据校验
      imageList: [], //宠物主图列表
      categoryLoad: false, //提交状态
    };
  },
  created() {
    //宠物获取类别详情
    this.getCategoryDetail();
  },
  watch:{
    'category.shapes'(newVal){
      this.category['shape']=newVal.join(',')
    }
  },
  methods: {
    /**
     * 获取宠物类别详情
     **/
    getCategoryDetail() {
      const id = this.$route.query.id;
      // 调用所有角色API
      this.$moe_api.PET_API.petCategoryDetail({ id }).then((data) => {
        if (data.code == 200) {
          let c = data.result;
          let shapes=c.shape.split(',').map(item=>{
            return Number(item);
          });
          // 回显宠物分类
          this.category = {
            id: c.id, //类别ID
            varieties: c.varieties, //类别名称
            pesType: c.pesType, //宠物类型
            shapes, //宠物体型
            weightMin: c.weightMin, //最小重量
            weightMax: c.weightMax, //最大重量
            img: this.$moe_yunHelper.formatterImageUrl(c.img), //宠物主图
          };

          // 宠物图片列表
          if (c.img) {
            this.imageList = [
              {
                url: c.img,
                tempFilePath: this.$moe_yunHelper.getTempFilePath(c.img),
              },
            ];
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /* 监听宠物重量范围 */
    petWeightChange() {
      let c = this.category;
      if (c.weightMin > c.weightMax) {
        [c.weightMin, c.weightMax] = [c.weightMax, c.weightMin];
      }
    },

    /* 提交宠物类别信息 */
    categoryInfoSubmit() {
      // 表单校验
      this.$refs['categoryForm'].validate(() => {
        this.categoryLoad = true;
        this.$moe_api.PET_API.petCategoryAmend(this.category)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('修改成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.categoryLoad = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.l-number-range {
  display: flex;
  align-items: center;

  .l-number-range__symbol {
    min-width: 35px;
    height: 1px;
    background-color: #acacac;
    border-radius: 1px;
    margin: 0 10px;
  }
}
</style>